import { template as template_dc50c99803df452a91d9a2325a058846 } from "@ember/template-compiler";
const FKText = template_dc50c99803df452a91d9a2325a058846(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
