import { template as template_5f0c8d8b73874f6ca1cae26e862524ab } from "@ember/template-compiler";
const WelcomeHeader = template_5f0c8d8b73874f6ca1cae26e862524ab(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
